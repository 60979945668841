import React from "react";
import "../../node_modules/bootstrap/dist/js/bootstrap.js";
import "../index.scss";

function Footer() {
  const sayHi = () => {
    let array = [];

    for (let i = 0; i < 3; i++) {
      array.push(
        <div className=" mx-auto ">
          <p>say Hi?</p>
        </div>
      );
    }
    return array;
  };
  return (
    <>
      {" "}
      <div id="footer">
        <div className="" id="contact">
          <div className="sayHiContainer">{sayHi()}</div>
          <div className="contactIcon linkedin">
            <a href="https://www.linkedin.com/in/holland-lee/">
              <i
                class="bi bi-linkedin"
                href="https://www.linkedin.com/in/holland-lee/"
              ></i>
            </a>
          </div>
          <div className="contactIcon  envelope">
            <a href="mailto:hollandly97@gmail.com">
              {" "}
              <i class="bi bi-envelope-fill"></i>
            </a>
          </div>
        </div>
        <div id="disclaimer">
          <p>This website is written with ReactJS by Holland</p>
          <p>© 2024 By Holland Lee</p>
        </div>
      </div>
    </>
  );
}

export default Footer;
