import React, { useState, useEffect, useRef } from "react";
import "../index.scss";
import "../scss/gallery.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

const folderDescriptions = {
  AutoCAD: {
    Name: "Engineering Drawings",
    Description: "AutoCAD 2D for engineering projects",
  },
  OnShape: {
    Name: "Room & Furniture Design",
    Description: "3D modeling with Solidworks/OnShape",
  },
  AdobeIllustrator: {
    Name: "Bus Ad Design",
    Description: "Graphic Design with Adobe Illustrator",
  },
  Blender: {
    Name: "Coffee & Donut Modelling",
    Description: "3D modeling, animation, and rendering with Blender",
  },
};
const importAll = (r) => r.keys().map(r);
const images = {
  AutoCAD: importAll(
    require.context("../img/gallery/AutoCAD", false, /\.(png|jpe?g|svg)$/)
  ),
  OnShape: importAll(
    require.context("../img/gallery/OnShape", false, /\.(png|jpe?g|svg)$/)
  ),
  AdobeIllustrator: importAll(
    require.context(
      "../img/gallery/AdobeIllustrator",
      false,
      /\.(png|jpe?g|svg)$/
    )
  ),
  Blender: importAll(
    require.context("../img/gallery/Blender", false, /\.(png|jpe?g|svg)$/)
  ),
};

const folders = Object.keys(images);

const ImageGrid = ({ folder, mainImage, setMainImage }) => (
  <div className="thumbnail-grid">
    {images[folder].map((image, index) => (
      <img
        key={index}
        src={image}
        alt={`${folder} ${index + 1}`}
        onClick={() => setMainImage(image)}
        className={`thumbnail ${image === mainImage ? "active" : ""}`}
      />
    ))}
  </div>
);

const Description = ({ folder }) => (
  <div className="description">
    <h2>{folder.Name}</h2>
    <p>{folder.Description}</p>
  </div>
);

const Gallery = () => {
  const [currentFolder, setCurrentFolder] = useState(0);
  const [mainImage, setMainImage] = useState(images[folders[0]][0]);
  const [imageStyle, setImageStyle] = useState({});
  const imageRef = useRef(null);

  useEffect(() => {
    setMainImage(images[folders[currentFolder]][0]);
  }, [currentFolder]);

  useEffect(() => {
    const img = new Image();
    img.src = mainImage;
    img.onload = () => {
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      const imgAspectRatio = img.width / img.height;
      const windowAspectRatio = windowWidth / windowHeight;

      let newWidth, newHeight;

      if (imgAspectRatio > windowAspectRatio) {
        newHeight = windowHeight;
        newWidth = newHeight * imgAspectRatio;
      } else {
        newWidth = windowWidth;
        newHeight = newWidth / imgAspectRatio;
      }

      const scale = folders[currentFolder] === "OnShape" ? 1 : 1;

      setImageStyle({
        width: `${newWidth * scale}px`,
        height: `${newHeight * scale}px`,
        maxHeight: "100vh",
        objectFit: "contain",
      });
    };
  }, [mainImage, currentFolder]);

  const handleNext = () => {
    setCurrentFolder((prev) => (prev + 1) % folders.length);
  };

  const handlePrev = () => {
    setCurrentFolder((prev) => (prev - 1 + folders.length) % folders.length);
  };

  return (
    <div id="carousel" className="carousel slide">
      <div className="carousel-title">
        <p className="subtitle">My Work</p>
      </div>
      <div className="carousel-indicators">
        {folders.map((folder, index) => (
          <button
            key={index}
            type="button"
            data-bs-target="#carousel"
            data-bs-slide-to={index}
            className={index === currentFolder ? "active " : ""}
            aria-current={index === currentFolder ? "true" : "false"}
            aria-label={`Slide ${index + 1}`}
            onClick={() => setCurrentFolder(index)}
          ></button>
        ))}
      </div>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <div className="main-image-container">
            <img
              ref={imageRef}
              src={mainImage}
              alt="Main"
              className="main-image"
              style={imageStyle}
            />
          </div>
          <div className="carousel-content">
            <ImageGrid
              folder={folders[currentFolder]}
              mainImage={mainImage}
              setMainImage={setMainImage}
            />
            <Description folder={folderDescriptions[folders[currentFolder]]} />
          </div>
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        onClick={handlePrev}
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        onClick={handleNext}
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default Gallery;
