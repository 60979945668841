import React from "react";
import "./index.scss";
import "../node_modules/bootstrap/dist/js/bootstrap.js";
import Selfie from "./components/selfie.js";
import Jobcard from "./components/job.js";

import Journey from "./components/journey.js";
import Gallery from "./components/gallery.js";
import Footer from "./components/footer.js";
import Nav from "./components/nav.js";
function Main() {
  return (
    <div className="main">
      <Nav></Nav>
      <div class="content">
        <Selfie></Selfie>
        <Jobcard></Jobcard>
        <Journey></Journey>
        <Gallery></Gallery>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default Main;
